<!--微信授权页面-->
<template>
  <div class="empower">
    <div class="close-empower" @click="closeEmpower">
      <van-icon name="cross" />
    </div>
    <div class="empower-box">
      <div class="empower-title">{{title}}</div>
      <div class="empower-subtitle">申请授权</div>
      <div class="empower-msg">
        <div class="empower-item">
          <i>
            <van-icon name="passed" />
          </i>
          <span>获取你的账号信息（昵称、头像、地区及性别）</span>

        </div>
        <div class="empower-item">
          <i>
            <van-icon name="passed" />
          </i>
          <span>阅读并同意：<i @click="showPopup(0)">《乐e居平台用户协议》</i> 及 <i @click="showPopup(1)">《乐e居平台隐私政策》</i></span>
        </div>
      </div>
    </div>
    <div class="empower-btn">
      <div class="agree-btn" @click="getEmpower">已阅读并同意授权</div>
      <div class="close-btn"  @click="closeEmpower">取消</div>
    </div>
    <van-popup v-model="show" :overlay="false" position="center"  ref='popup' @close="closePopup">
        <div class="popup-box" >
            <div class="popup-title">{{popupTitle}}</div>
            <div class="popup-msg" v-html="$xss(popupContent)"></div>
        </div>
    </van-popup>
    <div class="fixed-btn" v-if="show" @click="show = false">关闭</div>
  </div>
</template>
<script>
export default {
  name: "empower",
  data() {
    return {
      title: document.title,
      show: false,
      popupTitle:"",
      popupContent:``
    };
  },
  methods: {
    showPopup(index) {
      console.log(index+"获取到协议的值是什么")
      this.show = true;
       this.popupTitle = index == 0 ? '乐e居平台用户协议' : '乐e居平台隐私政策';
       const data = {
        type:index
       }
       this.$post("user/agreement",data).then((res) =>{
         if(res.status==200){
           this.popupContent = res.data
         }
       })
    },
    closeEmpower(){
        
        this.$router.go(-1)
    },
    getEmpower(){
        const local = decodeURIComponent(window.sessionStorage.getItem("skipAddress")) ||  this.BASE.BASE_URL;
         window.location.href = `${ this.BASE.WECHAT_AUTHORIZE_URL}?returnUrl=${local}`;
    },
    closePopup(){
      this.$refs.popup.$el.scrollTop = 0;
    }
  },
};
</script>
<style lang='less' scoped>
.empower {
  background: #fff;
  width: 100%;
  height: 100vh;
  padding: 10px 25px;
  box-sizing: border-box;
  position: relative;
  i {
    font-style: normal;
  }
  .close-empower {
    color: #000;
    font-size: 24px;
  }
  .empower-box {
    margin-top: 30px;
    color: #000;
    .empower-title {
      font-size: 24px;
      font-weight: 700;
    }
    .empower-subtitle {
      font-size: 24px;
      margin: 10px 0 20px;
    }
    .empower-msg {
      font-size: 14px;
      .empower-item {
        margin-bottom: 15px;
        display: flex;
        > i {
          font-size: 20px;
          line-height: 24px;
          margin-right: 8px;
          font-weight: 700;
        }
        span {
          display: inline-block;
          line-height: 24px;
          i {
            color: #15d8ca;
          }
        }
      }
    }
  }
  .empower-btn {
    position: absolute;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15%;
    div {
      width: 200px;
      line-height: 45px;
      text-align: center;
      border-radius: 6px;
      font-size: 16px;
      margin-top: 20px;
      font-weight: 500;
    }
    .agree-btn {
      background: #01c064;
      color: #fff;
    }
    .close-btn {
      color: #01c064;
      background: #f2f2f2;
    }
  }
  .fixed-btn {
    line-height: 50px;
    width: 100%;
    background: #01c064;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    position: fixed;
    letter-spacing: 5px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .van-popup{
      top: 0;
      left: 0;
        transform: unset;
  }
  .popup-box{
      width: 100vw;
      background: #fff;
      height: calc(100vh - 50px);
      color: #000;
      padding: 15px;
      box-sizing: border-box;
      .popup-title{
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }
      .popup-msg{
          color: #999;
          font-size: 16px;
          padding: 15px 0;
          text-align: justify;
      }
  }
}
</style>